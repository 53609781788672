<template>
  <div class="spinner"></div>
</template>

<script>
export default {}
</script>

<style scoped>
.spinner {
  margin: 0 auto;
  box-sizing: border-box;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  border: 5px solid grey;
  border-top-color: #000;
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
</style>
