<template>
  <div id="pincode-generate">
    <img class="logo" src="/Web/assets/img/logo-terraindex.svg" width="150" height="107" />
    <div v-if="generatingPincode" class="pincode-title">{{ $t('message.generatePincode.title') }}</div>
    <div v-else class="pincode-title">{{ $t('message.generatePincode.titlePincodeGenerated') }}</div>
    <div>
      <span v-show="generatingPincode">
        <div class="pincode-panel">
          <div v-if="generatePincodeError" class="pincode-error">{{ $t('message.generatePincode.error') }}</div>
          <B2CLoadingSpinner v-else />
        </div>
      </span>
      <div v-show="!generatingPincode">
        <div class="pincode-panel">
          <div class="pincode-instruction">
            <span class="instruction-text">{{ $t('message.generatePincode.instruction') }}</span>
            <form>
              <ul class="wrapper">
                <li class="form-row">
                  <label for="email">{{ $t('message.generatePincode.email') }}:</label>
                  <input id="email" v-model="email" type="text" name="email" readonly />
                </li>
                <li class="form-row">
                  <label for="pincode">{{ $t('message.generatePincode.pincode') }}:</label>
                  <input id="pincode" v-model="pincode" type="text" name="pincode"  readonly />
                </li>
                <li class="form-row" style="margin-top: 2rem">
                  <p id="log-off-text">{{ $t('message.generatePincode.logOffText') }}</p>
                </li>
                <li style="text-align: center;">
                  <button class="btn btn-danger" style="height: 5rem; font-size: 30px; width: 20rem" type="button" @click="logOut">{{ $t('message.generatePincode.logOff') }}</button>
                </li>
                <li v-if="queryFrom" class="form-row">
                  <button type="button" @click="handleRedirect">{{ $t('message.generatePincode.popup.back') }}</button>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from '@/configurations/config'
import B2CLoadingSpinner from './B2CLoadingSpinner.vue'
import authenticationProviders from '@/utils/authenticationProvider'

let authenticationProvider = authenticationProviders[config.platform].default

export default {
  name: 'GeneratePincode',
  data() {
    return {
      root: config.root,
      pincode: '',
      email: '',
      generatingPincode: true,
      generatePincodeError: false
    }
  },
  components: {
    B2CLoadingSpinner
  },
  async created() {
    if (this.$route.query.testpin) {
      this.email = 'foo'
      this.pincode = 'bar'
      this.generatingPincode = false
    } else if (this.queryToken) {
      await this.handleQueryToken()
    } else {
      window.location = config.sso.preLoginUrl + '?redirect_url=' + encodeURIComponent(window.location)
    }
  },
  computed: {
    queryFrom() {
      return this.$route.query.from
    },
    queryToken() {
      return this.$route.query.id_token
    }
  },
  methods: {
    async handleQueryToken() {
      try {
        this.generatingPincode = true
        this.generatePincodeError = false
        const generatePincodeResponse = await this.$store.dispatch('generateAndSavePincode', { idToken: this.queryToken })
        const generatedPincodeResponseObject = JSON.parse(generatePincodeResponse.body)
        this.email = generatedPincodeResponseObject.Email
        this.pincode = generatedPincodeResponseObject.Pincode
        this.generatingPincode = false
      } catch (error) {
        this.generatingPincode = false
        this.generatePincodeError = true
        this.error = error
      }
    },

    handleRedirect() {
      window.location = this.queryFrom
    },

    async logOut() {
      await this.$store.dispatch('clearLocalCache')
      authenticationProvider.signOut()
    }
  }
}
</script>

<style lang="less">
// Unscoped to hide Freshdesk help button.
// Faux scoping everything else inside #pincode-generate.
#freshworks-container {
  display: none;
}

#pincode-generate {
  background-image: url(/Web/assets/img/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center -600px;

  font-size: 16px;
  font-family: 'Roboto Flex', 'PT Sans', sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & #log-off {
    padding: 20px;
    margin: 0 auto;
    border: 0;
    background-color: #af3535;
    color: #fff;
    border-radius: 12px;
    display: block;
    -webkit-box-shadow: 0 2px 0 #661414;
    box-shadow: 0 2px 0 #661414;
    font-weight: 700;
    font-family: Roboto,sans-serif;
    font-size: 16px;
  }

  & #log-off-text {
    text-align: center;
  }

  & .pincode-title {
    color: white;
    text-align: center;
    text-shadow: 0 2px 2px #000000aa;
    font-size: 24px;
    font-weight: 500;
    margin: 2em 0 1em;
  }

  & .pincode-error {
    color: red;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
  }

  & .pincode-panel {
    font-size: 26px;
    margin-top: 2em;
    border-radius: 0.5em;
    padding: 2em 3em;
    background-color: white;
    max-width: 25em;
  }

  & .wrapper {
    list-style-type: none;
    padding: 0;
    border-radius: 3px;
  }

  & .form-row {
    display: flex;
    justify-content: flex-end;
    padding: .5em;
  }

  & .form-row > label {
    padding: .5em 1em .5em 0;
    flex: 1;
  }

  & .form-row > input {
    flex: 2;
    padding: .5em;
  }

  & .instruction-text {
    font-size: 16px;
  }

}
</style>
